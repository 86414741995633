#react-course {
     .jumbotron-fluid {
       padding: 15px;
       color: #fff;
       background-color: #6fc7eb;
       background: url('../../../../assets/images/spain-bg.jpg') #6fc7eb no-repeat bottom right -61px;
       text-align: left;
       margin-bottom: 15px;
       h1 {
         font-size: 2.5rem;
         margin-bottom: 2px;
         .title-subheading {
           font-size: 1rem;
           margin-left: -8px;
         }
       }
       .summary-description {
         font-weight: bold;
       }
       ul {
         list-style: none;
         margin-left: -25px;
         li:before {
           content: ''
         }
         .fa {
           margin-right: 7px;
           opacity: 1;
           color: #fff;
         }
       }



       .btn {
         font-weight: bold;
         font-size: 0.85rem;
         text-transform: uppercase;
         min-width: 225px;
         margin-left:15px;
         margin-right:15px;
         &.btn-primary {
           background-color: #b0ab36;
           border-color: #b0ab36;
           &:hover {
             background-color: #d2ca1e;
             border-color: #b0ab36;
           }
           .fa {
             color: #fff;
             opacity: 1;
           }
         };
         &.btn-light {
           color: #b0ab36;
           background-color: #fff;
           border-color: #fff;
           &:hover {
             color: #b0ab36;
             background-color: #e2e6ea;
           }
           &:active {
             color: #ffffff;
           }
           .fa {
             color: #b0ab36;
             opacity: 1;
           }
         }
       }
     }
   }
