#angular-course {
  .page-loading {
    width: 100%;
    height: 600px;
    margin: 200px auto;
  }

  a, a:hover {
    text-decoration: none !important;
  }
  .sub-heading {
    font-weight: bold;
    color: #a9ac6b;
  }
  .card {
    text-align: left;
    .card-header {
      color: #b0ab36;
      font-weight: bold;
      background-color: transparent;
    }
    .card-body {
      display: none;
      &.expanded {
        display: block;
      }
    }
  }

  .btn-actions {
    &.collapse {
      display: inline-block;
      font-size: 2rem;
      position: absolute;
      top: -2px;
      right: 7px;
    }
  }

  ul {
    list-style: none;
  }

  .fa {
    color: #b0ab36;
    opacity: 0.5;
  }
}

