

.container-fluid {
  &.clear {
    display: block;
    margin-top: 55px;
  }
}

footer {
  margin-top: 50px;
  text-align: center;
  background-color: #e8e8e8;
  height: 150px;
  ul {
    li {
      list-style-type: disc;
      display: inline-block;
      padding: 25px 5px 5px 25px;
      a {
        color: #8b8b8b;
        font-size: 0.8em;
      }
    }
  }
}
