#home-page {
    ul {
        list-style: none;
    }
    div.col-test {
        background: blue;
        opacity: 0.5;
        height: 300px;
        border: solid 1px white;
        padding: 15px;
    }

    .course-bg {
        display: block;
        background: #fff;
        opacity: 0.5;
        height: 216px;
        margin: 0 auto 15px 0;
        border-radius: 0.5rem;
    }

    .call-to-action {
        display: block;
        position: relative;
        bottom: 5px;
    }

    .testimonials {
        color: lightgrey;
        margin-top: 55px;
        text-align: center;
        .heading {
            font-weight: bold;
        }
        .testimony {
            font-style: italic;
            font-size: 1.5rem;
            font-weight: normal;
            font-family: "Times New Roman";
        }
        .witness {
            font-style: italic;
            font-size: 0.8rem;
        }
    }
}