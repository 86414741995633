#home-page {
  .jumbotron-fluid {
    padding: 15px;
    height: 500px;
    color: #fff;
    background-color: #6fc7eb;
    background: url('../../../assets/images/home-bg.jpg') #6fc7eb no-repeat bottom right -61px;
    text-align: left;
    margin-bottom: 17px;
    h1 {
      font-size: 2.5rem;
      margin-top: 15px;
      margin-bottom: -7px;
      .title-subheading {
        font-size: 1rem;
        margin-left: -8px;
      }
    }

    .title-subheading {
      font-size: 2rem;
      display: block;
      margin-bottom: 25px;
    }

    .summary-description {
      font-weight: bold;
      margin-top: 25px;
    }
    .topics {

    }

    .btn {
      font-weight: bold;
      font-size: 0.85rem;
      text-transform: uppercase;
      min-width: 225px;
      margin-left:15px;
      margin-right:15px;
      &.btn-primary {
        background-color: #b0ab36;
        border-color: #b0ab36;
        &:hover {
          background-color: #d2ca1e;
          border-color: #b0ab36;
        }
      };
      &.btn-light {
        color: #b0ab36;
        background-color: #fff;
        border-color: #fff;
        &:hover {
          color: #b0ab36;
          background-color: #e2e6ea;
        }
        &:active {
          color: #ffffff;
        }
      }
    }
  }

  .mortar-pestle {
    background: url('../../../assets/images/Font_Awesome_5_solid_mortar-pestle.svg.png') no-repeat center center;
    width: 21px;
    height: 18px;
    position: relative;
    background-size: 79%;
  }

  @media (max-width: 990px) {
    .jumbotron-fluid {
      height: auto;
      ul {
        font-size: 0.91rem;
      }
      .btn {
        margin-left: 10px;
        margin-right: 10px;
        min-width: auto;
        max-width: 157px;
        margin-bottom: 15px;
      }
    }
  }


  @media (max-width: 750px) {
    .jumbotron-fluid {
      height: auto;

      h1 {
        font-size: 2.4rem;
        margin-bottom: 0px;
      }

      .title-subheading {
        font-size: 1.4rem
      }

      .summary-description {
        font-weight: normal;
        font-size: 0.9rem;
      }

      ul {
        font-size: 0.9rem;
      }

      .btn {
        font-size: 0.7rem;
        min-width: auto;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 149px;
      }
    }
  }
  
  @media (max-width: 450px) {
    .jumbotron-fluid {
      height: auto;
      h1 {
        font-size: 3.4rem;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .title-subheading {
        font-size: 1rem;
      }

      .summary-description {
        font-weight: normal;
        margin-top: 25px;
        font-size: 0.85rem;
      }

      .btn.btn-primary {
        margin-bottom: 15px;
      }

      .btn.btn-primary,
      .btn.btn-light {
        width: 100%;
        max-width: 100%;
        font-size: initial;
      }
    }

    ul {
      font-size: 0.7rem;
    }
  }


}
